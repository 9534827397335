import {FormsStoreProps, OnlyFunctionProps} from '../../../types/app.types';
import {AddressWithContactModel} from '../../../domain/models/AddressWithContact.model';
import React, {ComponentType, createContext, useContext, useEffect, useState} from 'react';
import {useControllerProps} from '../Widget/ControllerContext';
import {ADD_NEW_ADDRESS_ID} from '../constants';
import {CheckoutModel} from '../../../domain/models/Checkout.model';
import {useFunctionResultObservation} from '@wix/function-result-observation';
import {isAddressSignificant} from '../../../domain/utils/isShippingDestinationSignificant';

export type MemberDetailsDataContextType = {
  selectedAddressesServiceId?: string;
  selectedAddressesService?: AddressWithContactModel;
  setAddressServiceById: (id?: string) => void;
  editMode: boolean;
  setEditMode: (editMode: boolean) => void;
  resetMemberDetailsState: ({isInitSelectValue}?: {isInitSelectValue?: boolean}) => void;
  areMemberDetailsValid: boolean;
  isAddNewChecked: boolean;
  setIsAddNewChecked: (checked: boolean) => void;
  isSetAsDefaultChecked: boolean;
  setIsSetAsDefaultChecked: (checked: boolean) => void;
};

export const MemberDetailsDataContext = createContext({} as MemberDetailsDataContextType);

/* eslint-disable sonarjs/cognitive-complexity */
export function withMemberDetailsData<T extends object>(
  Component: ComponentType<T>,
  getAddressAndContactFromCheckout: (checkout: CheckoutModel) => AddressWithContactModel | undefined,
  validationFunctionName: OnlyFunctionProps<FormsStoreProps>
) {
  return function Wrapper(props: T) {
    const {
      checkoutStore: {checkout},
      memberStore: {isAddressesAppInstalled, isMember, defaultAddressId, addressesInfo},
      formsStore,
    } = useControllerProps();
    const {wfConfig} = useControllerProps() as unknown as {wfConfig?: {formsById: any}};

    const {withObservation} = useFunctionResultObservation();
    const validate = withObservation(formsStore, validationFunctionName);

    const {
      selectedAddressesServiceId,
      selectedAddressesService,
      setAddressServiceById,
      resetAddressService,
      isInitSelectValue,
    } = useSelectedServiceAddress(getAddressAndContactFromCheckout(checkout));

    const [editMode, setEditMode] = useState(false);
    const [isAddNewChecked, setIsAddNewChecked] = useState(false);

    const [isSetAsDefaultChecked, setIsSetAsDefaultChecked] = useState(
      selectedAddressesServiceId === defaultAddressId || addressesInfo.addresses.length === 1
    );

    const [areMemberDetailsValid, setAreMemberDetailsValid] = useState(true);

    useEffect(
      () => {
        if (!isMember || !isAddressesAppInstalled || !wfConfig?.formsById || editMode) {
          return;
        }
        const validateData = async (addressWithContact: AddressWithContactModel) => {
          const isValid = await validate(addressWithContact);

          setAreMemberDetailsValid(isValid);
          if (isInitSelectValue && !isValid) {
            setEditMode(true);
          }
        };

        selectedAddressesService && void validateData(selectedAddressesService);
      },
      /* eslint-disable react-hooks/exhaustive-deps*/ [selectedAddressesServiceId, editMode]
    );

    const resetMemberDetailsState = ({isInitSelectValue}: {isInitSelectValue?: boolean} = {}) => {
      resetAddressService(isInitSelectValue);
      setEditMode(false);
    };

    return (
      <MemberDetailsDataContext.Provider
        value={{
          selectedAddressesServiceId,
          selectedAddressesService,
          setAddressServiceById,
          editMode,
          setEditMode,
          resetMemberDetailsState,
          areMemberDetailsValid,
          isAddNewChecked,
          setIsAddNewChecked,
          isSetAsDefaultChecked,
          setIsSetAsDefaultChecked,
        }}>
        <Component {...props} />
      </MemberDetailsDataContext.Provider>
    );
  };
}

function useSelectedServiceAddress(addressWithContact?: AddressWithContactModel) {
  const {
    memberStore: {addressesInfo, isAddressesAppInstalled, isMember, defaultAddressId},
  } = useControllerProps();

  const getInitialSelectedAddressesServiceId = (): string | undefined => {
    if (!isMember || !isAddressesAppInstalled) {
      return undefined;
    }

    if (addressWithContact?.addressesServiceId) {
      return addressWithContact?.addressesServiceId;
    }

    if (!isAddressSignificant(addressWithContact?.address)) {
      return addressesInfo.addresses?.length ? defaultAddressId : ADD_NEW_ADDRESS_ID;
    }

    if (isAddressSignificant(addressWithContact?.address) && addressesInfo.addresses?.length && !isInitSelectValue) {
      return defaultAddressId;
    }

    return undefined;
  };

  const getMemberAddressBySelectedServiceId = (id?: string) =>
    addressesInfo.addresses?.find((address) => address.addressesServiceId === id);

  const [isInitSelectValue, setIsInitSelectValue] = useState(true);
  const [selectedAddressesServiceId, setSelectedAddressesServiceId] = useState(getInitialSelectedAddressesServiceId);

  useEffect(
    () => {
      setAddressServiceById(getInitialSelectedAddressesServiceId());
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ [addressWithContact?.addressesServiceId]
  );

  useEffect(
    () => {
      if (addressWithContact?.addressesServiceId === selectedAddressesServiceId) {
        setAddressServiceById(selectedAddressesServiceId);
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ [addressWithContact]
  );

  const [selectedAddressesService, setSelectedAddressesService] = useState(
    getMemberAddressBySelectedServiceId(selectedAddressesServiceId)
  );

  const setAddressServiceById = (addressesServiceId?: string) => {
    setSelectedAddressesServiceId(addressesServiceId);
    setSelectedAddressesService(getMemberAddressBySelectedServiceId(addressesServiceId));
    setIsInitSelectValue(false);
  };

  const resetAddressService = (isInitSelectValue?: boolean) => {
    setAddressServiceById(getInitialSelectedAddressesServiceId());
    setIsInitSelectValue(!isInitSelectValue);
  };

  return {
    isInitSelectValue,
    selectedAddressesService,
    selectedAddressesServiceId,
    setAddressServiceById,
    resetAddressService,
  };
}

export function useMemberDetailsData() {
  const {
    selectedAddressesServiceId,
    selectedAddressesService,
    setAddressServiceById,
    editMode,
    setEditMode,
    resetMemberDetailsState,
    areMemberDetailsValid,
    isAddNewChecked,
    setIsAddNewChecked,
    isSetAsDefaultChecked,
    setIsSetAsDefaultChecked,
  } = useContext(MemberDetailsDataContext);

  return {
    selectedAddressesServiceId,
    selectedAddressesService,
    setAddressServiceById,
    editMode,
    setEditMode,
    resetMemberDetailsState,
    areMemberDetailsValid,
    isAddNewChecked,
    setIsAddNewChecked,
    isSetAsDefaultChecked,
    setIsSetAsDefaultChecked,
  };
}
